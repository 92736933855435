body {
  color: var(--foreground);
  background: var(--background);
}

a {
  color: var(--link);

  &:hover {
    color: var(--link-higher);
  }
}

img {
  height: auto;
  max-width: 100%;
  max-height: unset;
}

figcaption {
  color: var(--middleground);
  font-size: 90%;
}

// Photo Swipe also with angled images
.pswp__img {
  @include angled-images--sm;
}
