/// VERTICALLY ALIGN LABEL
/// Adds a pseudo-element to help vertically align
/// text labels in buttons/block-like links without
/// using magic numbers
/// More: https://ishadeed.com/article/button-label-alignment/
// https://gist.github.com/andy-piccalilli/433b8d7fb4e1882349238d1edb5bd556

@mixin vertically-align-label() {
  &::before {
    content: "";
    display: inline-block;
    height: 1rem;
    vertical-align: middle;
  }
}

// photo mixins
@mixin pictures {
  max-width: 100%;
  height: auto;
}

@mixin figcaptions {
  font-size: 0.8rem;
  color: var(--foreground-lower);
}

@mixin angled-images {
  clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0% 100%);
}

@mixin angled-images--sm {
  clip-path: polygon(0 2%, 100% 0%, 100% 98%, 0% 100%);
}

// angle
@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
  // Possible values for $pseudo are: before, after, both
  @if $pseudo == "before" or $pseudo == "after" or $pseudo == "both" {
    position: relative;
    background-size: cover;
    z-index: 1;

    $selector: if($pseudo == "both", "&:before,&:after", "&:#{$pseudo}");

    #{$selector} {
      background: inherit;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
    }

    @if $pseudo == "before" {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == "after" {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == "both" {
      &::before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &::after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}

// slanted edges here
.edge--bottom {
  @include angle(after);
}

.edge--bottom--reverse {
  @include angle(after, true);
}

.edge--top {
  @include angle(before);
}

.edge--top--reverse {
  @include angle(before, true);
}

.edge--both {
  @include angle(both);
}

.edge--both--reverse {
  @include angle(both, true);
}

// Cuts
@mixin cut {
  background-size: cover;
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    background: inherit;
    bottom: 0;
    content: "";
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-1.5deg);
    transform-origin: 100%;
    z-index: -1;
  }

  &::before {
    top: 0;
    transform: skewY(1.5deg);
    transform-origin: 100% 0;
  }

  &::after {
    bottom: 0;
    transform: skewY(-1.5deg);
    transform-origin: 100%;
  }
}

@mixin cut-reverse {
  background-size: cover;
  position: relative;
  z-index: 1;

  &::before,
  &::after {
    background: inherit;
    bottom: 0;
    content: "";
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(1.5deg);
    transform-origin: 100%;
    z-index: -1;
  }

  &::before {
    top: 0;
    transform: skewY(1.5deg);
    transform-origin: 100% 0;
  }

  &::after {
    bottom: 0;
    transform: skewY(1.5deg);
    transform-origin: 100%;
  }
}

// https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
// Respond above.
@mixin respond-above($grid-breakpoint) {
  // If the grid-breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $grid-breakpoint) {
    // Get the grid-breakpoint value.
    $grid-breakpoint-value: map-get($grid-breakpoints, $grid-breakpoint);

    // Write the media query.
    @media (min-width: $grid-breakpoint-value) {
      @content;
    }

    // If the grid-breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid grid-breakpoint: #{$grid-breakpoint}.';
  }
}

// Usage:
//   @include respond-above(sm) {
//     .element {
//       font-weight: bold;
//     }
//   }
// Output:
// @media (min-width: 768px) {
//     .element {
//       font-weight: bold;
//     }
// }

@mixin respond-below($grid-breakpoint) {
  // If the grid-breakpoint exists in the map.
  @if map-has-key($grid-breakpoints, $grid-breakpoint) {
    // Get the grid-breakpoint value.
    $grid-breakpoint-value: map-get($grid-breakpoints, $grid-breakpoint);

    // Write the media query.
    @media (max-width: ($grid-breakpoint-value - 1)) {
      @content;
    }

    // If the grid-breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid grid-breakpoint: #{$grid-breakpoint}.';
  }
}

@mixin respond-between($lower, $upper) {
  // If both the lower and upper grid-breakpoints exist in the map.
  @if map-has-key($grid-breakpoints, $lower) and map-has-key($grid-breakpoints, $upper) {
    // Get the lower and upper grid-breakpoints.
    $lower-grid-breakpoint: map-get($grid-breakpoints, $lower);
    $upper-grid-breakpoint: map-get($grid-breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-grid-breakpoint) and (max-width: ($upper-grid-breakpoint - 1)) {
      @content;
    }

    // If one or both of the grid-breakpoints don't exist.
  } @else {
    // If lower grid-breakpoint is invalid.
    @if  map-has-key($grid-breakpoints, $lower) == false {
      // Log a warning.
      @warn 'Your lower grid-breakpoint was invalid: #{$lower}.';
    }

    // If upper grid-breakpoint is invalid.
    @if  map-has-key($grid-breakpoints, $upper) == false {
      // Log a warning.
      @warn 'Your upper grid-breakpoint was invalid: #{$upper}.';
    }
  }
}
