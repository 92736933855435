@mixin flex() {
  display: flex;
}

@mixin flexColumn() {
  display: flex;
  flex-direction: column;
}

@mixin grid() {
  @include flex;
  flex-flow: column wrap;
  gap: var(--gap);

  @supports (grid-area: auto) {
    display: grid;
  }
}

@mixin gridLayout() {
  @include grid;
  @supports (grid-area: auto) {

    // https://ryanmulligan.dev/blog/layout-breakouts/
    .layout {
      &-content {
        grid-column: content;
      }

      &-popout {
        grid-column: popout;
      }

      &-featured {
        grid-column: feature;
      }

      &-full {
        grid-column: full;
      }
    }

    --content: min(80ch, 100% - var(--gap) * 2);
    --popout: minmax(0, 2rem);
    --feature: minmax(0, 10rem);
    --full: minmax(var(--gap), 1fr);

    grid-template-columns:
      [full-start] var(--full)
      [feature-start] var(--feature)
      [popout-start] var(--popout)
      [content-start] var(--content) [content-end]
      var(--popout) [popout-end]
      var(--feature) [feature-end]
      var(--full) [full-end];
  }
}

@mixin gridAuto() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--col), 1fr));
  }
}

@mixin gridAutoFit() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(var(--col), 1fr));
  }
}

@mixin grid-reset {
  grid-column: -1/1;
}

.grid {
  @include grid;
}

.grid-auto {
  @include gridAuto;
}

.grid-reset {
  @include grid-reset;
}
