body {
  > header {
    @include angle(after);

    background-color: var(--navbar-bg);
  }
}

// navbar
.navbar {
  flex-wrap: nowrap;

  &-nav {
    gap: var(--gap);

    .nav-link {
      color: var(--foreground);
      font-size: #{op.$font-size-fluid-1};

      &:hover {
        color: var(--foreground-higher);
      }
    }

    .nav-item {
      &.is_active > .nav-link {
        font-weight: 700;
        color: var(--foreground-higher);
      }

      .offcanvas.show & {
        margin-left: -0.5rem;
        padding-left: 0.5rem;
      }
    }
  }

  &-toggler {
    border-color: transparent;

    label {
      cursor: pointer;
    }

    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }

    .icon {
      fill: currentcolor;
    }
  }

  &-brand {
    --link-higher: var(--link);

    margin-bottom: 0;
    padding: 0;
    display: flex;
    align-items: center;
    overflow: hidden;

    svg {
      width: 20rem;
    }
  }
}

[data-theme="light"] {
  .navbar-custom {
    @extend .navbar-light;
  }
}

[data-theme="dark"] {
  .navbar-custom {
    @extend .navbar-dark;
  }
}

// offcanvas
.offcanvas-header {
  // .offcanvas-title {
  //   font-variant: small-caps;
  // }

  display: flex;
  justify-content: space-between;
}
