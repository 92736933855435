html,
body {
  height: 100%;
}

body {
  display: grid;
  grid-template-rows: auto 1fr auto;
  scrollbar-gutter: stable;

  > header,
  > footer,
  > main {
    @include gridLayout;

    gap: 0;
    align-content: flex-start;
  }

  > header {
    margin-block-end: calc(var(--gap) * 2);
  }

  > footer {
    margin-block-start: calc(var(--gap) * 2);
  }

  > main {
    > * {
      grid-column: content;
    }
  }

  // Header & Footer full-width
  > header,
  > footer {
    padding: 1rem;

    & > * {
      grid-column: full;
      gap: 1rem;
    }
  }

  wagtail-userbar {
    @include grid-reset;
  }

  // Footer
  > footer > nav {
    @include flex;

    button {
      margin-inline-start: auto;
    }
  }
}

// statements / Stimmen
body > main > article {
  @include flexColumn;

  &:has(.block--statement_block) {
    gap: 2rem;
  }
}

// Wer ist Liste
.wer-ist-habitat-2030 {
  // .block--paragraph_block:first-of-type {
  //   grid-column: feature;
  // }

  ul {
    columns: 2 30ch;
    column-gap: var(--gap);
    column-fill: balance;

    @extend .list-unstyled;

    li {
      break-inside: avoid-column;
    }

    // --col: 20rem;
    // @include gridAutoFit;
  }
}


// Charta
.charta {
  > main {
    gap: calc(var(--gap) * 0.3) 0;

    .block {
      &--heading_block {
        grid-column-start: popout-start;

        h2 {
          hyphens: none;
          text-decoration: underline;
          text-decoration-thickness: 0.5rem;
          text-decoration-color: var(--highlight-bg);
          text-underline-offset: 0.3rem;
        }
      }
    }
  }

  .block {
    &--paragraph_block {
      &:last-of-type {
        color: var(--middleground);
        font-size: 90%;
      }
    }
  }
}

// Content, Lists
.content {
  grid-column: content;

  &__list {
    --col: 14rem; // -> 12rem if 4 columns

    @include gridAutoFit;
    @include flexColumn;

    gap: var(--gap);

    figure {
      img {
        @include pictures;
        @include angled-images--sm;
      }
    }
  }

  h2,
  h3 {
    @include grid-reset;
  }

  article {
    @include flexColumn;

    gap: 0.5rem;
    position: relative;
    align-items: center;

    header {
      max-inline-size: max-content;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    footer {
      text-align: center;
    }
  }

}

// Sibling statements navigation
.sibling {
  &-navbar {
    @include flex;

    grid-column: feature;
    justify-content: space-between;
  }

  &-page {
    display: block;
  }
}
