/*!
* Habitat 2030 Website
* Krzysztof Jeziorny <h2030@jeziorny.net> 2024
* https://jeziorny.net
*/

@use "../../../node_modules/open-props-scss" as op;
@use "../../../node_modules/@fontsource-variable/inter/scss/mixins" as InterVariable;
@import "variables";
@import "mixins";
@import "bootstrap";
@import "grid";
@import "typography";
@import "layout";

// components
@import "components/block";

// @import "components/form";
@import "../../../node_modules/photoswipe/src/photoswipe";
@import "components/navbar";
@import "components/footer";
@import "components/icon";
@import "components/theme-toggle";
@import "style";
@import "a11y";
@import "print";
