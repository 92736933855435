@use 'sass:color';

// Wagtail blocks
.block {
  &--document_block {
    margin-block-end: 1rem;
  }

  // page block
  &--page_block {
    display: block;
    margin-block: 1rem;
  }

  // image block
  &--image_block {
    margin-inline: auto;
    max-inline-size: max-content;
    margin-block-end: var(--gap);

    body > main > & {
      // Images directly in article's body wider
      grid-column: feature;

      // cuts
      img {
        @include pictures;
        @include angled-images;
      }
    }
  }

  // AV
  &-media {
    audio,
    video {
      width: 100%;
      height: auto;
    }
  }

  // &--image_grid_block {
  //   ul {
  //     @include flex;

  //     list-style-type: "";
  //     margin-block: var(--gap);
  //     padding: unset;
  //     gap: var(--gap);
  //     align-items: center;

  //     figcaption {
  //       display: none;
  //     }
  //   }

  //   img {
  //     [data-theme="dark"] & {
  //       filter: grayscale(1);
  //       filter: invert(1);
  //     }

  //     @media (prefers-color-scheme: dark) {
  //       filter: grayscale(1);
  //       filter: invert(1);
  //     }
  //   }
  // }


  // Statements
  &--statement_block {
    --col: 30ch;

    display: grid;
    flex-flow: row wrap;
    grid-template-columns: repeat(auto-fill, minmax(min(var(--col), 100%), 1fr));
    gap: var(--gap);

    // .content__list > article:nth-child(even) & {
    //   @include media-breakpoint-up(sm) {
    //     figure {
    //       order: 2;
    //     }
    //   }
    // }

    figure {
      @include flexColumn;

      place-content: flex-start;
      justify-self: center;

      @include media-breakpoint-up(md) {
        position: sticky;
        top: 1rem;
        margin-block-start: 1rem;
        place-self: start center;
      }

      img {
        @include pictures;
        @include angled-images--sm;
      }

    }

    footer {
      font-style: italic;
      font-synthesis: style;
    }
  }
}
