@include InterVariable.faces(
  $subsets: (
    latin,
    latin-ext
  ),
  $weights: (
    300,
    400,
    500,
    600,
  ),
  $styles: all,
  $directory: "../font/",
);

// $modern-fonts-stack: avenir, "Avenir Next LT Pro", montserrat, corbel, "URW Gothic", source-sans-pro, $font-family-base; // https://modernfontstacks.com/

body {
  font-size: var(--font-size-body);
  font-family: $font-family-base;
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-optical-sizing: auto;
  font-weight: 400;
}

@supports (font-variation-settings: normal) {
  body {
    font-feature-settings: "calt", "kern", "dlig";
    font-synthesis: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter Variable", $font-family-base;
  font-weight: 700;
  font-variation-settings: "wght" 700;

  > a {
    text-decoration: none;
  }

  @include media-breakpoint-down(sm) {
    hyphens: auto;
  }
}

p {
  hyphens: auto;
}

em,
i {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
  font-variation-settings: "wght" 700;
}

code {
  color: currentcolor;
}

// Balance

h4,
h5 {
  text-wrap: balance;
}

h1 {
  font-size: clamp(2rem,9vw,2.2rem);
  margin-block-end: 1rem;
}

h2 {
  font-size: clamp(1.5rem,6vw,1.8rem);
  margin-block-end: 0.75rem;
}

h3 {
  font-size: #{op.$font-size-fluid-1};
  margin-block-end: 0.5rem;
}

// home
.template-homepage {
  main h2 {
    font-size: clamp(2.5rem,9vw,4rem);
    text-wrap: balance;

    // hyphens: auto;
    // hyphenate-character: "—";

    a {
      color: currentcolor;
      text-decoration: underline;
      text-decoration-thickness: 0.5rem;
      text-decoration-color: var(--middleground);
      text-underline-offset: 0.3rem;
    }
  }
}
