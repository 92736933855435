body {
  > footer {
    @include angle(before, true);

    background-color: #{op.$jungle-10}; // #658006, hsl(74 79.3% 36.1%)
    color: #{op.$jungle-2};
    font-size: 95%;

    a {
      color: var(--bs-white);
    }

    .icon {
      stroke: var(--bs-white);
    }
  }
}
